import { render, staticRenderFns } from "./LogoutButton.vue?vue&type=template&id=0b4af72b&scoped=true"
import script from "./LogoutButton.vue?vue&type=script&lang=js"
export * from "./LogoutButton.vue?vue&type=script&lang=js"
import style0 from "./LogoutButton.vue?vue&type=style&index=0&id=0b4af72b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4af72b",
  null
  
)

export default component.exports