import { render, staticRenderFns } from "./TweetComments.vue?vue&type=template&id=4f7d1205&scoped=true"
import script from "./TweetComments.vue?vue&type=script&lang=js"
export * from "./TweetComments.vue?vue&type=script&lang=js"
import style0 from "./TweetComments.vue?vue&type=style&index=0&id=4f7d1205&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f7d1205",
  null
  
)

export default component.exports